import { Classes } from "@blueprintjs/core";
import React from "react";
import styled, { css } from "styled-components";

import { HexTooltip } from "./HexTooltip.js";
import { KeyComboTag, KeyComboTagProps } from "./keyComboTag";

interface HexKeyComboProps extends KeyComboTagProps {
  showEscKey?: boolean;
  vimMode?: boolean;
  label?: string;
}

const StyledHexKeyCombo = styled(KeyComboTag)<HexKeyComboProps>`
  && {
    display: inline-flex;
    gap: 1px;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    text-transform: uppercase;

    color: ${({ theme }) => theme.fontColor.MUTED};
    font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};

    ${({ minimal, theme }) =>
      minimal &&
      css`
        padding: 0 4px;

        border: 1px solid ${theme.borderColor.MUTED};
        border-radius: ${theme.borderRadius};

        > .${Classes.ICON} {
          margin: 0;

          > svg {
            color: ${theme.iconColor};
          }
        }
      `}

    label {
      min-width: 12px;
      text-align: center;
      margin: 0;
      padding: 0 2px;
    }
  }
`;

const StyledHexKeyComboWrapper = styled.div`
  && {
    display: flex;
    gap: 4px;
    align-items: center;
    position: relative;
    font-size: ${({ theme }) => theme.fontSize.SMALL};
    color: ${({ theme }) => theme.fontColor.MUTED};

    svg {
      color: inherit;
    }
  }
`;

const KeyComboLabel = styled.div`
  display: flex;
  padding-right: 4px;
  color: ${({ theme }) => theme.fontColor.DEFAULT};
`;

const ThenSpan = styled.span`
  font-size: ${({ theme }) => theme.fontSize.EXTRA_SMALL};
`;

export const HexKeyCombo: React.ComponentType<HexKeyComboProps> = React.memo(
  function HexKeyCombo({ label, showEscKey, vimMode, ...props }) {
    if (showEscKey) {
      return (
        <HexTooltip
          autoFocus={false}
          content={`${vimMode ? "Shift+" : ""}Esc brings you to Command mode`}
          enforceFocus={false}
          openOnTargetFocus={false}
        >
          <StyledHexKeyComboWrapper>
            {label && <KeyComboLabel>{label}</KeyComboLabel>}
            <StyledHexKeyCombo
              combo={`${vimMode ? "shift+" : ""}esc`}
              minimal={true}
            />
            <ThenSpan>then</ThenSpan>
            <StyledHexKeyCombo {...props} minimal={true} />
          </StyledHexKeyComboWrapper>
        </HexTooltip>
      );
    }

    if (label) {
      return (
        <StyledHexKeyComboWrapper>
          <KeyComboLabel>{label}</KeyComboLabel>
          <StyledHexKeyCombo {...props} minimal={true} />
        </StyledHexKeyComboWrapper>
      );
    }

    return <StyledHexKeyCombo {...props} minimal={true} />;
  },
);
